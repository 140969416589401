import {DeliveryServices, DeliveryURL} from "../../../const/const";
import {setDeliveryData, ViewSelectedButton} from "../main_functions";
import {UserInfo} from "../../../types/types";
import {addBuyerInfo} from "../../../store/action";


// Почта России. Доставка курьером!

function courierStartWidget(t: any){
    const e=document.createElement("iframe");
    e.setAttribute("allowtransparency","true");
    e.setAttribute("scroll","false");
    e.setAttribute("frameborder","0");
    e.setAttribute("width","100%");
    e.setAttribute("height","100%");
    e.setAttribute("id","courierIframe");
    e.src=DeliveryURL.RussianMail.courier;
    const a=document.getElementById(t.containerId);
    document.querySelector("#courierIframe") || a?.appendChild(e);
    window.addEventListener("message", a => {
        if (a.data&&a.data.isCourierLoad) try {
            e.contentWindow?.postMessage({
                postData: {
                    siteId:t&&t.id,accountId:t&&t.accountId,accountType:t&&t.accountType,weight:t&&t.weight,sumoc:t&&t.sumoc,url:window.location.href
                }
            },"*");
        } catch ( t ){}
        a.data.deliveryData&&t.callbackFunction(a.data.deliveryData)
    });
}


export default function CourierRenderer (evt: any, userInfoState: UserInfo | undefined, dispatcher: any) {
    evt.preventDefault();
    const cdek_map: HTMLElement = document.getElementById('cdek-map') as HTMLElement
    cdek_map.innerHTML = ''
    cdek_map.style.height = '0';
    const element: HTMLElement = document.getElementById('ecom-widget') as HTMLElement
    element.innerHTML = ''
    element.style.height = '435px';
    ViewSelectedButton('2.1');

    if (userInfoState) {
        dispatcher(addBuyerInfo({
            ...userInfoState,
            deliveryService: DeliveryServices.RussianMail.delivery_service_name,
        }));
    }

    courierStartWidget({
        id: 52292,
        callbackFunction: (dataFromAPI: any) => setDeliveryData(dataFromAPI, userInfoState, dispatcher),
        containerId: 'ecom-widget'
    });
}