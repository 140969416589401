//  Пример использования функций
//
// <img
//     src="styles/img/main.jpg"
//     alt="The most popular item template."
//     id="firstPage popularItemTemplate"
//     onLoad={imageZoomIn("firstPage popularItemTemplate", "firstPage popularItemTemplate zoomer")}
// />
// <div className="image_mouse_zoomer" id="firstPage popularItemTemplate zoomer"/>


export function getCoords (element: HTMLElement) {
    const box = element.getBoundingClientRect();
    return {
        top: window.scrollY + box.top,
        bottom: window.scrollY + box.top + box.height,
        left: window.scrollX + box.left,
        right: window.scrollX + box.left + box.width,
        height: box.height,
        width: box.width
    }
}

function initMouseMove (zoomWindow: HTMLElement, image: HTMLElement, zoomFactor: number) {

    const imageCoords = getCoords(image);

    return function (event:any) {
        event.preventDefault();
        let pageX, pageY;
        if (event.targetTouches) {
            pageX = event.targetTouches[0].pageX;
            pageY = event.targetTouches[0].pageY;

            if (pageX > imageCoords.right) pageX = imageCoords.right;
            if (pageY > imageCoords.bottom) pageY = imageCoords.bottom;
            if (pageX < imageCoords.left) pageX = imageCoords.left;
            if (pageY < imageCoords.top) pageY = imageCoords.top;
        } else {
            pageX = event.pageX;
            pageY = event.pageY;
        }
        zoomWindow.style.display = "inline-block";
        const posX = pageX - image.offsetLeft;
        const posY = pageY - image.offsetTop;
        zoomWindow.style.backgroundPosition = (-posX * (zoomFactor - 1)) + "px " + (-posY * (zoomFactor - 1)) + "px";
    };
}

export function imageZoomIn(imageId: string, zoomWindowId: string, zoomFactor: number = 1.5) {
    const zoomWindow = document.getElementById(zoomWindowId);
    const image = document.getElementById(imageId);

    if (!zoomWindow || !image) {
        setTimeout(()=>{return imageZoomIn(imageId, zoomWindowId, zoomFactor)}, 100);
    } else {
        if (image.offsetWidth !== 0 || image.offsetHeight !== 0) {
            image.style.height = image.offsetHeight + "px";
            image.style.width = image.offsetWidth + "px";
        }

        const imageParent = image.parentElement;
        if (imageParent) {
            imageParent.style.height = imageParent.offsetHeight + "px";
            imageParent.style.width = imageParent.offsetWidth + "px";
        }

        image.style.position = "absolute";
        zoomWindow.style.height = image.offsetHeight + "px";
        zoomWindow.style.width = image.offsetWidth + "px";
        zoomWindow.style.backgroundImage = `url(${image.getAttribute("src")})`;
        zoomWindow.style.backgroundSize = image.offsetWidth * zoomFactor + "px " + image.offsetHeight * zoomFactor + "px";

        image.onmousemove = initMouseMove(zoomWindow, image, zoomFactor);
        zoomWindow.onmousemove = initMouseMove(zoomWindow, image, zoomFactor);
        zoomWindow.onmouseout = function () { zoomWindow.style.display = "none" }

        // for touchscreen
        image.ontouchmove = initMouseMove(zoomWindow, image, zoomFactor);
        zoomWindow.ontouchmove = initMouseMove(zoomWindow, image, zoomFactor);
        zoomWindow.ontouchend = function (e) {
            setTimeout(()=>zoomWindow.style.display = "none", 10);
        }
        image.ontouchend = function (e) {
            setTimeout(()=>zoomWindow.style.display = "none", 10);
        }
        return function () {}
    }
}