import {createAction} from '@reduxjs/toolkit';
import {
    CartOffers,
    CatalogRequest, DesignRequest,
    groupClothesBy,
    OfferStatus,
    ProductShort,
    Product, RandomCatalogRequest,
    sortClothesBy,
    UserInfo
} from "../types/types";

export const setBestProduct = createAction<ProductShort>('data/getFirstPage');

export const setOneElem = createAction<Product>('data/oneOffer');

export const loadCart = createAction('cart/load');

export const changeCartCount = createAction<number>('cart/add');

export const loadCartOffers = createAction<CartOffers>('cart/offers');

export const addBuyerInfo = createAction<UserInfo>('buyer/info');

export const createOffer = createAction<string>('create/offer');

export const findOfferStatus = createAction<OfferStatus>('data/findOffer');

export const setSort = createAction<sortClothesBy>('data/sort');

export const setGroup = createAction<groupClothesBy>('data/group');

export const loadCatalog = createAction<CatalogRequest>('data/catalog');

export const loadDesign = createAction<DesignRequest>('data/design');

export const loadProduct = createAction<Product>('data/product');

export const createOrderNextStep = createAction('createOrder/nextStep');

export const createOrderPrevStep = createAction('createOrder/prevStep');

export const setRandomCatalogs = createAction<RandomCatalogRequest>('catalog/random');