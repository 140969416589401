import {Svg} from "../../components/svg/svg";
import {LOCAL_STORAGE_NAMES} from "../../const/const";
import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import {findOffer} from "../../store/api-action";
import {ReactComponentElement, useEffect} from "react";
import {UserInfo} from "../../types/types";

export default function PaymentStatus (data: {userConfig: UserInfo | undefined }) {
    const dispatcher = useAppDispatch();

    const offerId = window.localStorage.getItem(LOCAL_STORAGE_NAMES.uniqid);

    useEffect( () => {
        dispatcher(findOffer(offerId? offerId : ''));
    }, []);

    let offerInfo = useAppSelector(state => state.offerStatus);

    if (!offerInfo) {
        return (
            <div className="main_container" >
                <div className="icon_handler" style={{width: '311px'}}>
                    <Svg type={'loader'} />
                </div>
            </div>
        );
    }

    let SVG: ReactComponentElement<any>;

    switch (offerInfo.status) {
        case 'successful':
            SVG = <Svg type={'success'} />;
            break;
        case 'pending':
            SVG = <Svg type={'waiting'} />;
            break;
        default:
            SVG = <Svg type={'error'} />;
            break;
    }

    return (
        <div>
            <div className="icon_handler payment_status_icon">
                {
                    SVG
                }
            </div>
            <div className="final_page black">
                <h2>{ offerInfo.status === 'successful' ? 'Спасибо за покупку !' : 'Ожидание оплаты...' }</h2>
                <h3>Номер заказа - <span className="black final_page_uniqid">{ offerInfo.id }</span></h3>
                <br />
                <div className="final_page_delivery">
                    <p className="final_page_text m-0">Доставка: { offerInfo.delivery.type }</p>
                    <p className="final_page_text m-0">Регион: { offerInfo.delivery.region }</p>
                    <p className="final_page_text m-0">Город: { offerInfo.delivery.cityTo }</p>
                    <p className="final_page_text m-0">Адрес: { offerInfo.delivery.address }</p>
                </div>
                <br />
                <p className="final_page_text">Вся информация по заказу будет приходить на вашу почту <span className="black">{offerInfo.email}</span></p>
                {
                    offerInfo.status === 'successful' ? <p className="final_page_text">Если письмо не пришло, проверьте папку спам</p> : ''
                }
                <p className="final_page_text">
                    При возникновении любых проблем с заказом, напишите нам на почту <a href="mailto:support@mky-shop.ru" className="black">support@mky-shop.ru</a>
                </p>
            </div>
        </div>
    );
}
