import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import FirstPage from "./pages/first_page/first_page";
import CartPage from "./pages/cart/cart";
import CreateOrderPage from "./pages/craete_order/create_order";
import Loader from "./pages/loader/loader";

import { store } from './store';
import {loadCart} from "./store/action";
import FaqPage from "./pages/faq/faq";
import AboutUsPage from "./pages/about_us/about_us";
import {Svg} from "./components/svg/svg";
import {GlobalAppRoutes, IS_MOBILE} from "./const/const";
import FaviconHandler from "./system_icons/favicon_handler";
import PublicOffer from "./pages/documents/public_offer/public_offer";
import PrivacyPolicy from "./pages/documents/privacy_policy/privacy_policy";
import CatalogPage from "./pages/catalog_page/catalog_page";
import DesignPage from "./pages/design_page/design_page";
import ProductPage from "./pages/product/product_page";
import DesignMobilePage from "./pages/design_page/design_mobile_page";
import ProductMobilePage from "./pages/product/product_mobile_page";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

export default function App() : JSX.Element {

    store.dispatch(loadCart());

    FaviconHandler();
    return (
        <BrowserRouter>
            <div id="notification_list" className="notification_list" />
            <Routes>
                <Route path="/">
                    <Route index element = {
                        <div>
                            <Header />
                            <Loader />
                            <FirstPage />
                            <Footer />
                        </div>
                    }
                    />
                    <Route path="index.html">
                        <Route index element = {
                            <div>
                                <Header />
                                <Loader />
                                <FirstPage />
                                <Footer />
                            </div>
                        } />
                    </Route>
                    <Route path="cart">
                        <Route index element = {
                            <div>
                                <Header />
                                <CartPage />
                                <Footer />
                            </div>
                        } />
                    </Route>
                    <Route path="catalog/:catalog_id">
                        <Route index element = {
                            <div>
                                <Header />
                                <Loader />
                                <CatalogPage />
                                <Footer />
                            </div>
                        } />
                    </Route>
                    <Route path="design/:design_id">
                        <Route index element = {
                            <div>
                                <Header />
                                <Loader />
                                {
                                    IS_MOBILE ? <DesignMobilePage/> : <DesignPage/>
                                }
                                <Footer />
                            </div>
                        } />
                    </Route>
                    <Route path="faq">
                        <Route index element = {
                            <div>
                                <Header />
                                <FaqPage />
                                <Footer />
                            </div>
                        } />
                    </Route>
                    <Route path="public_offer">
                        <Route index element = {
                            <div>
                                <Header />
                                <PublicOffer />
                                <Footer />
                            </div>
                        } />
                    </Route>
                    <Route path="privacy_policy">
                        <Route index element = {
                            <div>
                                <Header />
                                <PrivacyPolicy />
                                <Footer />
                            </div>
                        } />
                    </Route>
                    <Route path="about">
                        <Route index element = {
                            <div>
                                <Header />
                                <Loader />
                                <AboutUsPage />
                                <Footer />
                            </div>
                        } />
                    </Route>
                    <Route path="order">
                        <Route index element = {
                            <div>
                                <Header />
                                {/*<Loader />*/}
                                <CreateOrderPage />
                                <Footer />
                            </div>
                        } />
                    </Route>
                    <Route path="product/:product_id">
                        <Route index element = {
                            <div>
                                <Header />
                                <Loader />
                                {
                                    IS_MOBILE ? <ProductMobilePage/> : <ProductPage/>
                                }
                                <Footer />
                            </div>
                        }
                        />
                    </Route>
                    <Route path="*" element={
                        <div className="main_container" >
                            {
                                window.innerWidth > 850 ? <div className="icon_handler" style={{width: '800px'}}><a href={GlobalAppRoutes.main} ><Svg type={'404-page'} /></a></div> : <div className="icon_handler" style={{width: '260px', marginTop:'100px'}}><a href={GlobalAppRoutes.main} ><Svg type={'404-small-page'} /></a></div>
                            }
                        </div>
                    } />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

root.render(<Provider store={store}><App /></Provider>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals(console.log('fasfsfd'));
