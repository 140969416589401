import {DeliveryServices, DeliveryURL} from "../../../const/const";
import {setDeliveryData, ViewSelectedButton} from "../main_functions";
import {UserInfo} from "../../../types/types";
import {addBuyerInfo} from "../../../store/action";


// Почта России. Доставка в отделение.

function ecomStartWidget(t: any) {
    const map = document.createElement("iframe");
    map.setAttribute("allowtransparency", "true");
    map.setAttribute("scroll", "false");
    map.setAttribute("frameborder", "0");
    map.setAttribute("width", "100%");
    map.setAttribute("height", "100%");
    map.setAttribute("id", "mapIframe");
    map.src = DeliveryURL.RussianMail.map;

    const a = document.getElementById(t.containerId);
    !document.querySelector("#mapIframe") && a && a.appendChild(map);
    const c = (a: any) => {
        if (a.data && a.data.isMapLoad)
            try {
                // @ts-ignore
                map.contentWindow.postMessage({
                    postData: {
                        siteId: t && t.id,
                        accountId: t && t.accountId,
                        accountType: t && t.accountType,
                        weight: t && t.weight,
                        sumoc: t && t.sumoc,
                        startZip: t && t.startZip,
                        startAddress: t && t.start_location,
                        url: window.location.href
                    }
                }, "*")
            } catch (t) {
                window.removeEventListener("message", c)
            }
        a.data.pvzData && (t.callbackFunction ? t.callbackFunction(a.data.pvzData) : console.error("Ошибка вызова callback функции. Указанная функция не найдена."))
    };
    window.addEventListener("message", c)
}

export default function MapRenderer (evt: any, userInfoState: UserInfo | undefined, dispatcher: any) {
    evt.preventDefault();
    const cdek_map: HTMLElement = document.getElementById('cdek-map') as HTMLElement
    cdek_map.innerHTML = ''
    cdek_map.style.height = '0';
    const element: HTMLElement = document.getElementById('ecom-widget') as HTMLElement
    element.innerHTML = ''
    element.style.height = '500px';
    ViewSelectedButton('1.1');

    if (userInfoState) {
        dispatcher(addBuyerInfo({
            ...userInfoState,
            deliveryService: DeliveryServices.RussianMail.delivery_service_name,
        }));
    }

    ecomStartWidget({
        id: 52291,
        callbackFunction: (dataFromAPI: any) => setDeliveryData(dataFromAPI, userInfoState, dispatcher),
        containerId: 'ecom-widget',
    });
}