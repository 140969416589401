import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import {useEffect} from "react";
import CourierRenderer from "../../api/delivery/russian_mail/courier";
import MapRenderer from "../../api/delivery/russian_mail/pvz";
import {setDeliveryData} from "../../api/delivery/main_functions";
import CdekCourierWidgetRenderer from "../../api/delivery/cdek/courier";
import CdekPvzWidgetRenderer from "../../api/delivery/cdek/pvz";

export default function Delivery () {
    let userInfo = useAppSelector(state => state.userConfig);
    const dispatcher = useAppDispatch();

    //TODO: Убрать этот эффект
    useEffect(()=>{
        setDeliveryData(userInfo, dispatcher, false);
    }, [])

    return (
        <section className="delivery_component">
            <div>
                <h4 className="black bold m-0">Доставка в отделение:</h4>
                <div className="delivery_type__container">
                    <div id="1.1" className="cart_form" onClick={(e) => MapRenderer(e, userInfo, dispatcher)}>Почта
                        России
                    </div>
                    <div id="1.2" className="cart_form" onClick={(e) => {CdekPvzWidgetRenderer(e)}}>CDEK</div>
                </div>
            </div>

            <div>
                <h4 className="black bold m-0">Доставка курьером:</h4>
                <div className="delivery_type__container">
                    <div id="2.1" className="cart_form" onClick={(e) => CourierRenderer(e, userInfo, dispatcher)}>Почта России</div>
                    <div id="2.2" className="cart_form" onClick={(e) => {CdekCourierWidgetRenderer(e)}}>CDEK</div>
                </div>
            </div>
        </section>
    );
}
