import {createReducer} from '@reduxjs/toolkit';
import {StateType} from "../types/types";
import {
  addBuyerInfo,
  changeCartCount, createOffer, createOrderNextStep, createOrderPrevStep, findOfferStatus,
  loadCartOffers, loadCatalog, loadDesign, loadProduct,
  setBestProduct, setGroup,
  setOneElem, setRandomCatalogs, setSort
} from "./action";
import {CreateOrderStep, errorState, LOCAL_STORAGE_NAMES} from "../const/const";
const initialState: StateType = {
  createOrderStep: CreateOrderStep.personality,
  oneOffer: undefined,
  cart: [],
  cartCount: 0,
  sortClothesBy: 'default',
  groupClothesBy: 'all',
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setBestProduct, (state, action)=>{
    state.bestProduct = action.payload;
  });

  builder.addCase(setOneElem, (state, action)=>{
    state.oneOffer = action.payload;
  });

  builder.addCase(changeCartCount, (state, action) => {
    state.cartCount = action.payload
  });

  builder.addCase(loadCartOffers, (state, action) => {
    state.cartData = action.payload;
  });

  builder.addCase(addBuyerInfo, (state, action) => {
    state.userConfig = action.payload;
    console.log(state.userConfig)
  });

  builder.addCase(createOffer, (state, action) => {
    window.localStorage.setItem(LOCAL_STORAGE_NAMES.uniqid, action.payload);
  });

  builder.addCase(findOfferStatus, (state, action) => {
    state.offerStatus = action.payload;
  });

  builder.addCase(setSort, (state, action) => {
    state.sortClothesBy = action.payload;
  });

  builder.addCase(setGroup, (state, action) => {
    state.groupClothesBy = action.payload;
  });

  builder.addCase(loadCatalog, (state, action) => {
    state.catalog = action.payload;
  })

  builder.addCase(loadDesign, (state, action) => {
    state.design = action.payload;
  })

  builder.addCase(loadProduct, (state, action) => {
    state.product = action.payload;
  })

  builder.addCase(createOrderNextStep, (state) => {
    state.createOrderStep += 1;
    console.log(state.createOrderStep);
  })

  builder.addCase(createOrderPrevStep, (state) => {
    state.createOrderStep -= 1;
  })

  builder.addCase(setRandomCatalogs, (state, action) => {
    state.randomCatalogs = action.payload === errorState? errorState : action.payload.catalog;
  })
});

