import OrderTable from "../../components/order_table/order_table";
import {useAppSelector} from "../../hooks/redux/redux";
import {useEffect} from "react";
import {onloadHandler} from "../loader/loader";
import ProgressBar from "./progress_bar";
import CreateOrderPersonality from "./personality";
import CreateOrderButtons from "./buttons";
import CreateOrderDelivery from "./delivery";
import CreateOrderCheckData from "./check_data";
import PaymentStatus from "../payment_status/payment_status";
import {AppRoute, CreateOrderStep} from "../../const/const";

export default function CreateOrderPage() {
    const createOrderStep = useAppSelector(state => state.createOrderStep);
    const userInfo = useAppSelector(state => state.userConfig);

    useEffect(() => {
        document.title = 'MKY | Оформление заказа';
    }, []);

    return (
        <div className="main_container" onLoad={onloadHandler}>
            <h1 className="cart_h1">Оформление заказа</h1>
            <ProgressBar step={createOrderStep}/>
            <main className={createOrderStep === CreateOrderStep.payment ? "create-order flex-column" : "create-order"}>
                <div className="create-order_user-info">
                    {
                        createOrderStep != CreateOrderStep.personality ? <div /> : <CreateOrderPersonality userConfig={userInfo} />
                    }
                    {
                        createOrderStep != CreateOrderStep.delivery ? <div /> : <CreateOrderDelivery />
                    }
                    {
                        createOrderStep != CreateOrderStep.checkData ? <div /> : <CreateOrderCheckData userConfig={userInfo} />
                    }
                    {
                        createOrderStep != CreateOrderStep.payment ? <div /> : <PaymentStatus userConfig={userInfo} />
                    }
                </div>
                <div className="create-order_order-info">
                    <OrderTable step={createOrderStep} />
                    <CreateOrderButtons step={createOrderStep} />
                </div>
            </main>
            {
                createOrderStep != CreateOrderStep.delivery ? <div/> : <div><div id="ecom-widget"/> <div id="cdek-map"/></div>
            }
        </div>
    );
}

