// Функция выделения кнопки по её ID
import {UserInfo} from "../../types/types";
import {addBuyerInfo} from "../../store/action";
import appNotification from "../../global_functions/notify";

export function ViewSelectedButton (id: string) {
    const button = document.getElementById(id);

    if (button && !button.classList.contains('active')) {
        document.querySelectorAll(".delivery_component .cart_form").forEach((el) => el.classList.remove('active'));
        button.classList.add('active');
    }
}

// Функция вставки всей хуйни в состояние
export function setDeliveryData(actualUserInfoState: UserInfo | undefined, dispatcher: any, originalDeliveryDataFromAPI: any) {

    if (!actualUserInfoState) {
        appNotification("error", "Ошибка предыдущего этапа (отсутствует информация о пользователе.)")
        actualUserInfoState = {
            firstName: '',
            lastName: '',
            number: '',
            email: '',
            middleName: '',
            deliveryService: null,
            delivery: null
        }
    }

    if (originalDeliveryDataFromAPI) {
        dispatcher(addBuyerInfo({
            ...actualUserInfoState,
            delivery: UniversalDeliveryDataDefinition(originalDeliveryDataFromAPI),
        }));
    }

    //TODO: убрать
    dispatcher(addBuyerInfo({
        ...actualUserInfoState,
        delivery: {
            regionTo: "Респ Татарстан (regionTo)",
            areaTo: "areaTo",
            cityTo:"г Казань (cityTo)",
            addressTo:"ул Черноморская 3 (addressTo)",
            indexTo:420066,
            mailType:"ONLINE_PARCEL(mailType)",
            pvzType:"russian_post(pvz_type)",
            comment:"1 день   Обработка заказа: 2 дня (comment)",
            weight: 600,
            location: '(location)',
            cashOfDelivery:15923
        },
    }))
}


// TODO:
//  Необходимо внедрить сюда универсальную функцию, определяющую от какого сервиса пришёл ответ (почта Росси / Сдек)
//  и определяющюю ньюансы доставки. Ещё хочу, чтобы функция возвращала сразу отформатированную информацию и оригинальную.
//  Чтобы записать оба ответа в состояние. В будующем это поможет сделать защиту от мошенников.
function UniversalDeliveryDataDefinition (originalDeliveryDataFromAPI: any) {
    return originalDeliveryDataFromAPI;
}