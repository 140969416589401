import {AppNotificationType} from "../types/types";

const PROTOCOL = 'https://';

// export const CLIENT_URL = PROTOCOL + HOST + ':3000';
export const CLIENT_URL = PROTOCOL + 'test-mky-shop.ru';
export const API_URL = PROTOCOL + 'api.test-mky-shop.ru';
export const IMG_SERVER_URL =  PROTOCOL + 'img.test-mky-shop.ru';

export const IS_MOBILE = window.innerWidth <= 769;

export const InstagramURL = "https://instagram.com/mky_shop.ru";
export const TelegramURL = "https://t.me/mky_shop";
export const VkontakteURL = "https://vk.com/club215908105";
export const YouTubeURL = "https://www.youtube.com/@mky-shop";

export const DeliveryURL = {
    RussianMail: {
        map: "https://widget.pochta.ru/map/",
        courier: "https://widget.pochta.ru/courier/"
    },
    Cdek: {
        widget: "https://delivery.test-mky-shop.ru/service.php"
    }
}

export const changePhotoMethod = {
    main: 'main',
    secondary: 'secondary'
}

export const AppRoute = {
    main: '/',
    faq: '/faq',
    offerList : '/catalog/1',
    oneOffer: '/items/',
    cart: '/cart',
    order: '/order',
    products: '/product/',
    designs: '/design/'
}

export const GlobalAppRoutes = {
    main: CLIENT_URL,
    faq: CLIENT_URL + '/faq',
    publicOffer: CLIENT_URL + '/public_offer',
    privacyPolicy: CLIENT_URL + '/privacy_policy',
    clothes: CLIENT_URL + '/catalog/1',
    cart: CLIENT_URL + '/cart',
    order: CLIENT_URL + '/order',
}

export const EmailRegularExpression = `^[-a-z0-9_]+(\\.[-a-z0-9_]+)*@([a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\\.)*(aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|[a-z][a-z])$`;

export const NameRegularExpression = "[- a-zA-zА-Яа-яёЕ]*$";

export const NumberAllowedSymbols = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '+'];

export const ApiRoute = {
    oneOffer: 'one-item.php?id=', // + item_id
    createOrder: '/order/new',
    findOffer: 'paymentStatus.php',
    bestProduct: '/product/best',
    catalog: 'catalog/', // + catalog_id
    design: 'design/', // + design_id
    addDesignToCart: 'cart/add/design',
    addProductToCart: 'cart/add/product',
    cart: 'cart',
    cartCount: 'cart/count',
    removeFromCart: 'cart/remove',
    product: '/product/', // + product_id
    getRandomCatalogs: '/catalog/random'
}

export const LOCAL_STORAGE_NAMES = {
    session: 'MKY-SESSION',
    uniqid: 'MKY-ID',
    cart: 'need_delete'
}

export const IMG_SERVER_PATH = {
    clothes: IMG_SERVER_URL + '/clothes/',
    designs: IMG_SERVER_URL + '/designs/',
    generated: IMG_SERVER_URL + '/generated/',
    category: IMG_SERVER_URL + '/category/',
}

export const CreateOrderStep = {
    personality: 1,
    delivery: 2,
    checkData: 3,
    payment: 4
}

export const AppNotification: AppNotificationType = {
    success: 'success',
    warning: 'warning',
    error: 'error',
    info: 'info'
}

export const DeliveryServices = {
    RussianMail: {
        delivery_service_name: "russian_post",
        courier: "Почта России | Курьер",
        pvz: "Почта России | В отеделение",
    },
    CDEK: {
        delivery_service_name: "cdek",
        courier: "CDEK | Курьер",
        pvz: "CDEK | В отеделение",
    }
}

export const errorState = "ERROR";
